import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OrdersView from '../views/OrdersView.vue'
import HistoryView from '../views/HistoryView.vue'
import PrintView from '../views/PrintView.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/orders/:index',
    name: 'Orders',
    props:true,
    component: OrdersView
  },
  {
    path: '/history/',
    name: 'History',
    component: HistoryView
  },
  {
    path: '/print/:index',
    name: 'Print',
    props:true,
    component: PrintView
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
